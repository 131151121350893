// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://api-my-chicken.epitglobal.com/api/', // 'http://localhost:8000/api/', // 
  BASE_URL: 'https://api-my-chicken.epitglobal.com', // 'http://localhost:8000',// 
  DEFAULT_LANG: 'de',
  APP_VERSION: '0.1', //change app version to force clear cache
  GOOGLE_COUNTRIES: ["DE"], //for google address
  GOOGLE_LOGIN_WEB_CLIENT_ID: '114583049190-njum27j3gmhc3111qvnr69e6pt3qu79r.apps.googleusercontent.com'
  // '114583049190-n7c7bqob6balsijt1vg9cjna5hg62vr1.apps.googleusercontent.com' // '213938997454-0kvmu2ordml4l5lpai2ofn3dueobkoqu.apps.googleusercontent.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
